import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	modulo?: number;
	seccion?: number;
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
		{
            path: "/app/inicio",
            title: "Inicio",
            icon: "fa-dashboard",
            type: "link",
            bookmark: true,
            active: true
        },
		{
			title: 'Acreditaciones', icon: 'fa-tasks', type: 'sub', active: false, children: [
				{ path: '/app/acreditaciones/gestionar', title: 'Gestionar', type: 'link' }
			]
		},
		{
			title: 'Reportes',
			icon: 'fa-bar-chart-o',
			type: 'sub',
			active: false,
			modulo: 8,
			children: [
				{
					path: '/app/reportes/documentos-empresas',
					title: 'Documentos de Empresas',
					type: 'link',
					seccion: 26
				},
				{
					path: '/app/reportes/documentos-trabajadores',
					title: 'Documentos de Trabajadores',
					type: 'link',
					seccion: 27
				},
				{
					path: '/app/reportes/documentos-maquinarias',
					title: 'Documentos de Maquinarias',
					type: 'link',
					seccion: 28
				}
			]
		},
		{
			title: "Empresas",
            icon: "fa-building-o",
            type: "sub",
            active: false,
            modulo: 2,
			children: [
				{ 
					path: '/app/empresas',
					title: 'Empresas',
					type: 'link',
					seccion: 7
				},
				{ 
					path: '/app/centros-costos',
					title: 'Centros de Costos',
					type: 'link',
					seccion: 8
				}
			]
		},
		{
			title: "Trabajadores",
            icon: "fa-male",
            type: "sub",
            active: false,
            modulo: 3,
			children: [
				{ 
					path: '/app/trabajadores',
					title: 'Trabajadores',
					type: 'link',
					seccion: 9
				},
				{ 
					path: '/app/cargos',
					title: 'Cargos',
					type: 'link',
					seccion: 10
				}
			]
		},
		{
			title: "Maestros",
            icon: "fa-th-list",
            type: "sub",
            active: false,
            modulo: 4,
			children: [
				{ 
					path: '/app/maestros/documentos',
					title: 'Documentos',
					type: 'link',
					seccion: 11
				},
				{ 
					path: '/app/maestros/personal',
					title: 'Personal',
					type: 'link',
					seccion: 12
				},
				{ 
					path: '/app/maestros/periodos',
					title: 'Periodos',
					type: 'link',
					seccion: 13
				}
			]
		},
		{
			title: "Mantenedores",
            icon: "fa-align-justify",
            type: "sub",
            active: false,
            modulo: 5,
			children: [
				{ 
					path: '/app/mantenedores/instituciones-previsionales',
					title: 'Inst. Previsionales',
					type: 'link',
					seccion: 14
				},
				{ 
					path: '/app/mantenedores/paises',
					title: 'Países',
					type: 'link',
					seccion: 15
				},
				{ 
					path: '/app/mantenedores/regiones',
					title: 'Regiones',
					type: 'link',
					seccion: 16
				},
				{ 
					path: '/app/mantenedores/ciudades',
					title: 'Ciudades',
					type: 'link',
					seccion: 17
				},
				{ 
					path: '/app/mantenedores/comunas',
					title: 'Comunas',
					type: 'link',
					seccion: 18
				}
			]
		},
		{
            title: "Centro de Usuarios",
            icon: "fa-users",
            type: "sub",
            active: false,
            modulo: 6,
            children: [
                { 
					path: '/app/centrousuarios/usuarios',
					title: 'Usuarios',
					type: 'link',
					seccion: 19
				},
				{ 
					path: '/app/centrousuarios/bitacora',
					title: 'Bitácora',
					type: 'link',
					seccion: 20
				}
            ]
        },
		{
            title: "Configuraciones",
            icon: "fa-cog",
            type: "sub",
            active: false,
            modulo: 7,
            children: [
				{ 
					path: '/app/configuraciones/parametros',
					title: 'Parámetros',
					type: 'link',
					seccion: 21
				},
				{ 
					path: '/app/configuraciones/perfiles',
					title: 'Perfiles',
					type: 'link',
					seccion: 22
				},
				{ 
					path: '/app/configuraciones/planes',
					title: 'planes',
					type: 'link',
					seccion: 25
				}
            ]
        }
	];	

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

}
